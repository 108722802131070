import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import "../scss/branch.scss"
import SEO from "../components/seo"
import Layout from "../components/layout"
import useSiteMetadata from "../utils/useSiteMetadata"

import SubheadingText from "../components/basic-components/subheading-text"


import loadable from '@loadable/component'

import { FaCheckCircle } from "react-icons/fa";

const Button = loadable(() => import("../components/basic-components/button"))
const Signup = loadable(() => import("../components/signup"))


export const query = graphql`
{
  strapiContactPage {
    SEOTitle
    SEODescription
    Canonical
    MetaTags{
      MetaName
      MetaContent
    }
    Address
    BranchName
    ContactFormToken
    Email
    Subheader
    MapImage {
      localFile{
        publicURL
      }
    }
    HeaderImage {
      localFile{
        publicURL
        childImageSharp{
          fluid(quality: 90, maxWidth: 1900) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    PhoneNo
    strapiId
    OpeningHours
  }

  allStrapiBranches {
    edges {
      node {
        BranchName
        strapiId
        Email
        PhoneNo
      }
    }
  }

  staticMap(nickname: {eq: "contact"}) {
    childFile {
      publicURL
    }
    mapUrl
  }
}
`



const Contact = ({ data, pageContext }) => {

  var headerData = {
    headerTitle: data.strapiContactPage.BranchName,
    headerDescription: "",
    headerCTAPrimaryLabel: "",
    headerCTAPrimaryUrl: "",
    headerCTASecondaryLabel: "",
    headerCTASecondaryUrl: "",
    headerBgImage: data.strapiContactPage.HeaderImage.localFile.childImageSharp.fluid,
    headerRightBgColor: "",
    headerLeftBgColor: '',
  };

  const [visible, setVisible] = useState(true);

  
  const sendBranchMessage = event => {

    var form = document.getElementById("branch-contact-form")
    // console.log(form.elements["branchmessage-enquiry-type"].value) //returns selected radio option
    // console.log(form)


    var cform = {
      FirstName: form.elements["branchmessage-first-name"].value,
      LastName: form.elements["branchmessage-last-name"].value,
      Email: form.elements["branchmessage-email"].value,
      PhoneNo: form.elements["branchmessage-phone-no"].value,
      // EnquiryType: form.elements["branchmessage-enquiry-type"].value,
      Message: form.elements["branchmessage-message"].value,
      AcceptOffers: form.elements["branchmessage-offers"].checked,
    }

    // console.log(cform)


    var submitBtn = document.getElementById("sendBranchMessage").firstChild;
    submitBtn.innerHTML = '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="spinner" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"></path></svg>';
    submitBtn.classList.add("form-submitting");

    var body = {
      Token: data.strapiContactPage.ContactFormToken,
      FormData: JSON.stringify(cform)
    };

    // console.log(body, "message sent!")

    fetch("https://api.phaeria.com/api/home/SendEmailNotification", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response =>
      response.text().then(function (responseText) {
        // console.log("response text: " + responseText);
        if (responseText.localeCompare('"Form sent successfully."') === 0) {
          //success send message
          // console.log("success"+ responseText);
          submitBtn.innerHTML = "Thanks for reaching out, we’ll be in touch shortly.";
          submitBtn.classList.remove("form-submitting");
          submitBtn.classList.add("hide-btn");
          setVisible(false);

        } else {
          //error sending message
          // console.log("something went wrong");
          submitBtn.innerHTML = "Oops... Try again!";
          submitBtn.classList.remove("form-submitting");
        }


      })
    )
    let spamDomains = ["mailinator", "aosod.com", "guerrillamail", "spam4.me", "pokemail.net", "sharklasers.com", "tcwlx.com", "yopmail.com", "nfstripss.com", "maildrop.cc"];
    let isSpam = 0
    spamDomains.forEach(function (domain, index) {
      if (JSON.stringify(cform).includes(domain)) {
        isSpam = 1;
      }
    });

    if (isSpam == 0) {
      fetch("https://api.phaeria.com/api/home/SendEmailNotification", {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response =>
        response.text().then(function (responseText) {
          // console.log("response text: " + responseText);
          if (responseText.localeCompare('"Form sent successfully."') === 0) {
            //success send message
            // console.log("success"+ responseText);
            submitBtn.innerHTML = "Thanks for reaching out, we’ll be in touch shortly.";
            submitBtn.classList.remove("form-submitting");
          } else {
            //error sending message
            // console.log("something went wrong");
            submitBtn.innerHTML = "Oops... Try again!";
            submitBtn.classList.remove("form-submitting");
          }


        })
      )
    }
    else {

      submitBtn.innerHTML = "Thanks for reaching out, we’ll be in touch shortly.";
      submitBtn.classList.remove("form-submitting");
    }
    // end of fetch

    // console.log("Message sent");

    event.preventDefault();
  }

  const handleGtag = (title) => {
    if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {

      window.gtag('event', 'contact-form', { 'event_category': 'ecommerce', 'event_label': `${title}` });

    }
  }

  const handleGtagHQPhone = (title) => {
    if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {

      window.gtag('event', 'branch-call', { 'event_category': 'ecommerce', 'event_label': `${title}` });

    }
  }

  const handleGtagHQEmail = (title) => {
    if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {

      window.gtag('event', 'branch-email', { 'event_category': 'ecommerce', 'event_label': `${title}` });

    }
  }

  const handleGtagBranchPhone = (title) => {
    if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {

      window.gtag('event', 'branch-call', { 'event_category': 'ecommerce', 'event_label': `${title}` });

    }
  }

  const handleGtagBranchEmail = (title) => {
    if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {

      window.gtag('event', 'branch-email', { 'event_category': 'ecommerce', 'event_label': `${title}` });

    }
  }

  const { siteUrl: url } = useSiteMetadata();


  return (
    <div>
      <Layout pageData={headerData} headerShow={true} headerType="contact">
        <SEO title={data.strapiContactPage.SEOTitle} description={data.strapiContactPage.SEODescription} 
             meta={data.strapiContactPage.MetaTags}
             canonical={data.strapiContactPage.Canonical ? data.strapiContactPage.Canonical : `${url}`}/>


        <SubheadingText text={data.strapiContactPage.Subheader} />



        <div className="branch-page-wrapper">
          <div className="branch-page">



            <section className="branch-details">

              <div className="branch-section-title">Our Details</div>
              <hr />

              <div className="details-section">

                <div className="uk-grid-collapse uk-child-width-expand@s" data-uk-grid>
                  <div className="multiline-text" dangerouslySetInnerHTML={{ __html: data.strapiContactPage.Address }}></div>
                  <div className="">
                    <div onClick={() => handleGtagHQPhone(data.strapiContactPage.BranchName)}><a href={`tel:${data.strapiContactPage.PhoneNo}`} className="phone-link">{data.strapiContactPage.PhoneNo}</a></div>
                    <div className="branch-email" onClick={() => handleGtagHQEmail(data.strapiContactPage.BranchName)}><a className="phone-link" href={"mailto:" + data.strapiContactPage.Email}>{data.strapiContactPage.Email}</a></div>
                  </div>
                </div>

              </div>

              <hr />

              <div className="details-section">

                <div className="uk-grid-collapse uk-child-width-expand@s" data-uk-grid>
                  <div>
                    <div className="column-title">Opening Hours</div>
                    <div className="multiline-text" dangerouslySetInnerHTML={{ __html: data.strapiContactPage.OpeningHours }}></div>

                  </div>
                  <div className="">
                  </div>
                </div>

              </div>

              <div className="map-section">

                <a href={data.staticMap.mapUrl} target="_blank">
                  <div className="image-section uk-height-medium uk-cover-container uk-margin-none">
                    <img src={data.staticMap.childFile.publicURL || ""} alt={data.strapiContactPage.BranchName + " map"} data-uk-cover />

                  </div>
                </a>
                {console.log(data.staticMap.childFile.publicURL, "map")}

              </div>

              <div className="enquiry-btn-wrap">
                  <a href="#enquiry-popup" aria-label="enquiry button">
                      <Button use="" label="Enquiry"></Button>
                  </a>
              </div>



              {/* <div className="form-section">

                <form id="branch-contact-form" onSubmit={sendBranchMessage}>

                  <div className="additional-info-box">
                    <h3>Send {data.strapiContactPage.BranchName} a message</h3>
                    <hr />
                    <p>To help us connect you with the right team member, we need some details from you. </p>
                    <fieldset className="uk-fieldset" data-uk-grid>
                      <div className="uk-margin uk-width-1-2@s">
                        <label className="uk-form-label" htmlFor="form-stacked-text">First name</label>
                        <input id="branchmessage-first-name" className="uk-input" type="text" placeholder="" />
                      </div>
                      <div className=" uk-width-1-2@s">
                        <label className="uk-form-label" htmlFor="form-stacked-text">Last name</label>
                        <input id="branchmessage-last-name" className="uk-input" type="text" placeholder="" />
                      </div>
                      <div className="uk-margin uk-width-1-2@s">
                        <label className="uk-form-label" htmlFor="form-stacked-text">Email address</label>
                        <input id="branchmessage-email" className="uk-input" type="text" placeholder="" />
                      </div>
                      <div className="uk-margin uk-width-1-2@s">
                        <label className="uk-form-label" htmlFor="form-stacked-text">Phone no.</label>
                        <input id="branchmessage-phone-no" className="uk-input" type="text" placeholder="" />
                      </div>
                      
                      <div className="uk-margin uk-width-1-1@s">
                        <label className="uk-form-label" htmlFor="form-stacked-text">How can we help?</label>
                        <textarea id="branchmessage-message" className="uk-input" name="text" rows="10" placeholder="" ></textarea>
                      </div>
                      <div className="uk-margin uk-width-1-1@s">
                        <label className="uk-form-label" htmlFor="form-stacked-text">
                          <input type="checkbox" id="branchmessage-offers" name="product-type" value="Accept Offers" ></input>
                          We’d like to send you further information about our products and services, including special offers and recommendations. To receive these occasional emails, check this box.
                        </label>
                      </div>
                    </fieldset>

                    <div id="sendBranchMessage" onClick={() => handleGtag(data.strapiContactPage.BranchName)}><Button use="" label="Send Message"></Button></div>

                    {
                      visible === false ?
                        <div className="product__add__btn__message uk-animation-slide-top showMessage">
                          <FaCheckCircle color={"#2E9F71"} />
                          The message was successfully sent. Thank you!

                        </div>
                        : ""
                    }

                  </div>

                </form>

              </div> */}

              

            </section>

            <section className="other-branches">
              <div className="branch-section-title">Our other branches</div>

              {data.allStrapiBranches.edges.map((item, index) => {
                return (
                  <div key={index}>
                    <hr />
                    <div className="uk-grid-collapse uk-child-width-expand@s" data-uk-grid>
                      <div>
                        <div className="branch-name-fs">{item.node.BranchName}</div>
                        <div><Link to={"/branch/" + item.node.BranchName.replace(/[\s+.]/g, '-').replace(/[*+~.,_^&#£%|()'"?!:@]/g, '').toLowerCase()} className='branch-name-fs'>Find out more</Link></div>
                      </div>
                      <div className="">
                        <div onClick={() => handleGtagBranchPhone(item.node.BranchName)}><a href={`tel:${item.node.PhoneNo}`} className="phone-link">{item.node.PhoneNo}</a></div>
                        <div className="branch-email phone-link" onClick={() => handleGtagBranchEmail(item.node.BranchName)}><a href={"mailto:" + item.node.Email}>{item.node.Email}</a></div>
                      </div>
                    </div>

                  </div>
                )
              })}




            </section>





          </div>


          <Signup
            title="Sign Up to Our Newsletter"
            description="Get updates on all the lastest information, or stay informed about our courses"
            buttonLabel="Sign Up"
          />




        </div>


      </Layout>



    </div>
  )
}

export default Contact